import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios';

Vue.config.productionTip = false;



import Nombre from '@/views/components/global/nombre/Nombre';
Vue.component('Nombre', Nombre);

import Nombres from '@/views/components/global/nombre/Nombres';
Vue.component('Nombres', Nombres);

Vue.mixin({
  computed: {
    MTM_GOAL_TUTO_OPEN: () => 1,
    MTM_GOAL_TUTO_FINISH: () => 2,
    MTM_GOAL_TUTO_DISMISS: () => 3,
    MTM_GOAL_TUTO_UNFINISH: () => 4,
    MTM_GOAL_GAME_RESTART: () => 5,
    MTM_GOAL_GAME_NEW: () => 6,
    MTM_GOAL_GAME_DOWNLOAD: () => 7,
    MTM_GOAL_SOUND_MUTE: () => 8,
    MTM_GOAL_SOUND_UNMUTE: () => 9,
    MTM_GOAL_GAME_START: () => 10,
    //MTM_GOAL_GAME_JOIN: () => 11,
    MTM_GOAL_GAME_FINISH: () => 12,
    //MTM_GOAL_GAME_RECONNECT: () => 13,
    MTM_GOAL_GAME_BTN_SHARE: () => 14,
    MTM_GOAL_GAME_COPY_LINK: () => 15,
    MTM_GOAL_KICK_E0: () => 16,
    MTM_GOAL_KICK_IN_GAME: () => 17,
  },

  methods: {
    randBetween(min, max) {
      return  Math.floor(Math.random() * (max - min + 1) + min);
    },

    _matomo_setCustomVariable(index, name, value, scope, trackPageView) {
      if ('_paq' in window) {
        window._paq.push(['setCustomVariable', index, name, value, scope]);
        if (trackPageView)
          window._paq.push(['trackPageView']);
      }
    },

    _matomo_trackGoal(goalId, trackPageView=false) {
      if ('_paq' in window) {
        window._paq.push(['trackGoal', goalId]);

        if (trackPageView)
          window._paq.push(['trackPageView']);
      }
    },
  },
});

import VueCookies from 'vue-cookies'

VueCookies.config('30d');

import SessionPlugin from '@/resources/js/vue_plugins/session'

Vue.use(SessionPlugin, {
  cookie_name: 'sketchy-vue',
  VueCookies: VueCookies,
});

const event_bus = new Vue();
Vue.prototype.$bus = event_bus;

import axios from 'axios'

// Définition d'objets récupérables sur les 'this'
Object.defineProperties(Vue.prototype, {
  $axios: {
    get: () => axios
  },
  $url_serveur: {
    get: function () {
      return window.appConfig.urlServeur;
    }
  },
  $url_serveur_ws: {
    get: function () {
      return window.appConfig.urlServeurWs;
    }
  },
  $url_service_trad: {
    get: function () {
      return window.appConfig.urlServiceTrad;
    }
  },
  $dev: {
    get: function () {
      return window.appConfig.urlServeur.includes('http://127.0.0.1') ||
          window.appConfig.urlServeur.includes('http://192.168.');
    }
  },
  $sketchy_pseudo_max: {
    get: () => 25
  },
  $sketchy_nom_salon_max: {
    get: () => 40
  },
  $sketchy_pass_max: {
    get: () => 64
  },
  $sketchy_chatbox_max: {
    get: () => 128
  },
  $sketchy_mot_max: {
    get: () => 256
  },
  $sketchy_current_version: {
    get: () => 12
  },
  $isSafari: {
    get: () => {
      let userAgent = navigator.userAgent.toLowerCase()+'';
      if (userAgent.indexOf('safari') !== -1) {
        if (userAgent.indexOf('chrome') > -1) {
          //alert("1") // Chrome
          return false;
        } else {
          //alert("2") // Safari
          return true;
        }
      }
      return false;
    },
  },
});

// vue-i18n, pour gérer la localisation (traductions)
//   https://github.com/kazupon/vue-i18n
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

function _checkLocalStorageWorking() {
  if (!localStorage || !localStorage.setItem || !localStorage.removeItem) return false;
  let test = '_localstorage_test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

const localStorageTraductionPrefix = '_localstorage_sketchy_vue_trad_';
const localStorageWorking = _checkLocalStorageWorking();

function _getStaleTradLocalStorage(lng) {
  if (!localStorageWorking) return null;
  const lngTrad = localStorage.getItem(localStorageTraductionPrefix + lng);
  if (!lngTrad) return null;
  if (typeof lngTrad !== 'string') return null;
  try {
    return JSON.parse(lngTrad);
  } catch (e) {
    return null;
  }
}

/*************** DEBUT CODE CHARGEMENT LANGUES **************/
let locale = Vue.prototype.$session._language;
console.log('Langue', 'Lecture langue session, $session._language =', locale);
if (!locale) {
  locale = window.navigator.userLanguage || window.navigator.language;
  console.log('Langue', 'Langue session invalide, lecture langue navigateur =', locale);
  if (!locale) {
    // Si le navigateur n'a pas de langue par défaut configurée, on prends français
    locale = 'fr';
    console.log('Langue', 'Langue navigateur invalide, utilisation défaut =', locale);
  }
} else {
  console.log('Langue', 'Langue session valide =', locale);
}

let langueFound = false;
const languesDispo = ['fr', 'en'];
locale = locale.toLowerCase().trim().slice(0, 2);
for (let i in languesDispo) {
  if (locale === languesDispo[i]) {
    langueFound = true;
    locale = languesDispo[i];
    break;
  }
}
if (!langueFound) locale = 'en';
console.log('Langue', 'langueFound =', langueFound);
console.log('Langue', 'Utilisation finale de la langue =', locale);

// Chargement de la locale anglaise par défaut si traduction manquante, utilisation du fr si on est déjà en anglais
let fallbackLocale = 'en';
if (locale === 'en')
  fallbackLocale = 'fr';


const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: fallbackLocale,
  messages: {},
});

let _i18n_loadedLanguages = {};
let _i18n_requetesEnCours = {};

const _i18n_loadLanguageAsync = function (locale, retryDuration, resolve, reject) {
  if (!retryDuration) {
    retryDuration = 350;
  }

  console.log('Langue', 'Demande de chargement de la langue \'' + locale + '\' en async...');

  if (locale in _i18n_loadedLanguages) {
    console.log('Langue', 'Langue \'' + locale + '\' déjà chargée, requête ignorée');
    return;
  }

  if (locale in _i18n_requetesEnCours && _i18n_requetesEnCours[locale]) {
    console.log('Langue', 'Requête ignorée : chargement déjà en cours pour langue \'' + locale + '\'');
    return;
  }
  _i18n_requetesEnCours[locale] = true;

  const staleLng = _getStaleTradLocalStorage(locale);
  console.log('Langue', 'staleLng =', staleLng);
  if (staleLng !== null && typeof staleLng === 'object') {
    console.log('Langue', 'Utilisation langue localstorage en attendant fin requête');
    i18n.setLocaleMessage(locale, staleLng);

    if (resolve !== null && resolve !== undefined)
      resolve();
  }

  console.log('Langue', 'Requête récupération langue \'' + locale + '\' en cours...');
  Axios.get(Vue.prototype.$url_service_trad + '/trad/' + locale + '.json')
      .then((resp) => {
        _i18n_requetesEnCours[locale] = false;

        console.info('langue', 'Langue \'' + locale + '\' chargée avec succès !');
        _i18n_loadedLanguages[locale] = resp.data;
        i18n.setLocaleMessage(locale, resp.data);

        if (localStorageWorking)
          localStorage.setItem(localStorageTraductionPrefix + locale, JSON.stringify(resp.data));

        if (resolve !== null && resolve !== undefined)
          resolve();
      })
      .catch((err) => {
        _i18n_requetesEnCours[locale] = false;

        console.error('langue', 'Erreur lors du chargement de la langue \'' + locale + '\' !');
        console.error('langue', 'err =', err);

        console.log('Langue', 'Création d\'un timeout pour nouvelle tentative dans', retryDuration, 'ms');
        setTimeout(function () {
          let newRetryDuration = retryDuration * 2;
          if (newRetryDuration > 3000) newRetryDuration = 3000;
          _i18n_loadLanguageAsync(locale, newRetryDuration);
        }, retryDuration);
      });
}

Vue.prototype.$localeOk = null;

// Solution pour watch la locale
// https://github.com/kazupon/vue-i18n/issues/253#issuecomment-372123348
Object.defineProperty(Vue.prototype, '$locale', {
  get() {
    return i18n.locale;
  },
  set(locale) {
    if (Vue.prototype.$localeOk === null) {
      Vue.prototype.$localeOk = new Promise((resolve, reject) => {
        _i18n_loadLanguageAsync(locale, undefined, resolve, reject);
      });
    } else {
      _i18n_loadLanguageAsync(locale);
    }
    i18n.locale = locale;

    i18n.fallbackLocale = locale === 'en' ? 'fr' : 'en';
    console.info('langue', 'Sélection langue \'' + locale + '\' / fallback => \'' + i18n.fallbackLocale + '\'');
    _i18n_loadLanguageAsync(i18n.fallbackLocale);

    Vue.prototype.$session._language = locale;
    document.querySelector('html').setAttribute('lang', locale);
  }
});


Vue.prototype.$locale = locale;

// Hommage aux pseudos trouvés par les joueurs
// Ce ne sont pas des jean, ils n'iront pas dans la liste
// Mais ils sont suffisament inventifs pour mériter une place ici
//
// - Roland Culé
// - Dimitri Sélectif

Vue.prototype.$jeans = [
  'Jean-Tourloupe',
  'Jean-Racine',
  'Jean-Tartine',
  'Jean-Tartinette',
  'Jean-Fume',
  'Jean-Tortille',
  'Jean-Fourne',
  'Jean-Flamme',
  'Jean-Platre',
  'Jean-Bon',
  'Jean-Be',
  'Jean-Roule',
  'Jean-Sérien',
  'Jean-Peuplu',
  'Jean-Neymar',
  'Jean-Ti',
  'Jean-Fonce',
  'Jean-Baguette',
  'Jean-Tube',
  'Jean-Nuit',
  'Jean-Tends',
  'Jean-Terre',
  'Jean-Barque',
  'Jean-Vole',
  'Jean-Ploie',
  'Jean-Lève',
  'Jean-Poigne',
  'Jean-Prunte',
  'Jean-Pire',
  'Jean-Chêne',
  'Jean-Cadre',
  'Jean-Chaîne',
  'Jean-Clume',
  'Jean-Bonbeurre',
  'Jean-Bombeur',
  'Jean-Brasse',
  'Jean-Ferme',
  'Jean-Voix',
  'Jean-Jambe',
  'Jean-Profite',
  'Jean-Conclus',
  'Jean-Suissur',
  'Jean-Ris',
  'Jean-Riz',
  'Jean-Veux',
  'Jean-Reveux',
  'Jean-Ressors',
  'Jean-Rougis',
  'Jean-Traîne',
  'Jean-FêTouThunPla',
  'Jean-Fourche',
  'Jean',
  'Jean-MarqueDesButs',
  'Jean-Dors',
  'Jean-Pile',
  //'Jean-Négro',
  //'Jean-Naigro',
  'Jean-Nezgro',
  'Jean-Négrosurlapatate',
  'Jean-Chante',
  'Jean-Tremble',
  'Jean-Sorcelle',
  'Jean-Déduis',
  'Jean-Doute',
  'Jean-Rhume',
  'Jean-Lise',
  'Jean-Passédéméyeur', // J'en passe, et des meilleurs
  'Jean-Mourrais',
  'Jean-Mange',
  'Jean-Croapamézieu',
  'Jean-Goisse',
  'Jean-Registre',
  'Jean-Pranote', // J'en prends note
  'Jean-Chie',
  'Jean-Tanplularadio',
  'Jean-Bête',
  'Jean-Bave',
  'Jean-Neypleinlenez',
  'Jean-Mène',
  'Jean-Saigne',
  'Jean-Treprend',
  'Jean-Tanrien',
  'Jean-File',
  'Jean-Phil',
  'Jean-Temps',
  'Jean-Édeu',
  'Jean-Néplu',
  //'Jean-Découvrepleindautres',
  'Jean-Découvre',
  'Jean-Darme',
  'Jean-Darmerie',
  'Jean-Cherche',
  'Jean-Courage',
  'Jean-Castre',
  'Jean-Branlepasune',
  'Jean-Bonnaud',
  'Jean-Visage',
  'Jean-Tour',
  'Jean-Tasse',
  'Jean-Lasse',
  'Jean-Richis',
  'Jean-Broche',
  'Jean-Venime',
  'Jean-Bobine',
  'Jean-Ménage',
  'Jean-Bière',
  'Jean-Neige',
  'Jean-Balle',
  'Jean-Vier',
  'Jean-Te',
  'Jean-Tre',
  'Jean-Tripote',
  'Jean-Mérite',
  'Jean-Pale',
  'Jean-Brume',
  'Jean-Ticipe',
  'Jean-Clanche',
  'Jean-Porte',
  'Jean-Presse',
  'Jean-Gloutis',
  'Jean-Duis',
  'Jean-Étale',
  'Jean-Crasse',
  'Jean-Brouille',
  'Jean-Rage',
  'Jean-Soleil',
  'Jean-Tame',
  'Jean-Chéri',
  'Jean-Paille',
  'Jean-Fouis',
  'Jean-Magazine',
  'Jean-Tlemanne',
  'Jean-Graisse',
  'Jean-Gendre',
  'Jean-Trave',
  'Jean-Sevelis',
  'Jean-Globe',
  'Jean-Robe',
  'Jean-Tretien',
  'Jean-Bellis',
  'Jean-Piffre',
  'Jean-Barasse',
  'Jean-Veloppe',
  'Jean-Bouche',
  'Jean-Cive',
  'Jean-Cercle',
  'Jean-Bourbe',
  'Jean-Prisonne',
  'Jean-Taille',
  'Jean-Gueule',
  'Jean-Passe',
  'Jean-Peste',
  'Jean-Duvoyage', // Gens du voyage
  'Jean-Permémo', // J'en perds mes mots
  'Jean-Patize', // J'enpatise
  'Jean-Étésur', // J'en était sûr
  'Jean-Talu', // Jantes alu
  'Jean-Népa', // J'en ai pas
  'Jean-Bambois', // Jambe en bois
  'Jean-Quiquine', // J'enquiquine
  'Jean-Voidulour', // J'envoie du lourd
  'Jean-Titouplin', // Gentil tout plein
  'Jean-Pastex', // ?
  'Jean-Capsule', // J'encapsule
  'Jean-Trouvre', // J'entrouvre
  'Jean-Trouve', // J'en trouve
  'Jean-Trouvpa', // J'en trouve pas
  'Jean-Vent', // J'en vends
  'Jean-Vends', // J'en vends
  'Jean-Achète', // J'en achète
  'Jean-Refourgue', // J'en refourgue
  //'Jean Case Texte', // ?
  'Jean-Perlatète', // J'en perds la tête
  'Jean-Quête', // J'enquête
  'Jean-Baume', // J'embaume
  'Jean-Bauche', // J'embauche
  'Jean-Tanlelou', // J'entends le loup
  'Jean-Farine', // J'enfarine
  'Jean-Tampa', // J'entends pas
  'Jean-Rêve', // J'en rêve
  'Jean-Vestis', // J'investis
  'Jean-Banlère', // Jambes en l'air
  'Jean-Tertémor', // J'enterre tes morts
  'Jean-Kul', // J'encule
  'Jean-Kulasek', // J'encule à sec
  'Jean-Sens', // J'encense
  'Jean-Épari', // J'en ai pas ri
  'Jean-Reviens', // J'en reviens
  'Jean-Revienpa', // J'en reviens pas
  'Jean-Sézi', // J'en saisis
  'Jean-Térifi', // J'en terrifie
  'Jean-Tiga', // Gentil gars
  'Jean-Timek', // Gentil mec
  'Jean-Grossi', // J'en grossis
  'Jean-Merde', // J'emmerde
  'Jean-Mitoufle', // J'emmitoufle
  'Jean-Mur', // J'emmure
  'Jean-Modi', // J'en maudis
  'Jean-Veupa', // J'en veux pas
  'Jean-Vibien', // J'en vis bien
  'Jean-Raffole', // J'en raffole

  'Jeanne-Alyse',
  'Jeanne-Ime',
  'Jeanne-Ule',
  'Jeanne-Nihile',
  'Jeanne-Note',
  'Jeanne-Obli',

  'Jeanne-Alphabète',
  'Jeanne-Oréxique',
  'Jeanne-Onimize',
  'Jeanne-Ulaire',
  'Jeanne-Anasse',
  'Jeanne-Omalie',
  'Jeanne-Esthésie',
];

Vue.prototype.$jeans_en = [
  'Lee King', // Leaking
  'Wall King', // Walking
  'Joe King', // Joking
  'Ass King', // Asking
  'Bae King', // Baking
  'Ran King', // Ranking
  'Bye King', // Biking
  'Mill King', // Milking
  'Boo King', // Booking
  'Park King', // Parking
  'Ban King', // Banking
  'Whore King', // Working
  'Chuck King', // Chocking
  'Thin King', // Thinking
  'Core King', // Corking
  'Bar King', // Barking
  'Low King', // Locking
  'Tea King', // Ticking
  'Pea King', // Picking
  'Sin King', // Sinking
  'Three King', // Tricking
  'Lie King', // Liking
  'Hi King', // Hiking
  'Sting King', // Stinking
  'Stall King', // Stalking
  'Span King', // Spanking
  'Tall King', // Talking
  'Tree King', // Tricking
  'Gimme King', // Gimmicking
  'Pew King', // Puking
  'Duh King', // Ducking
  'Inn king', // Inking
  'May King', // Making
  'Blow King', // Blocking
  'New King', // Nuking
  'Moneyma King', // Money making
  'Twee King', // Tweaking
  'Twea King', // Tweaking
  'Twer King', // Twerking
  'Cook King', // Cooking
  'Pow King', // Poking
  'Ray King', // Raking
  'Slink King', // Slinking
  'Quirk King', // Quirking
  'Nark King', // Narking
  'Free King', // Freaking
  'Shrink King', // Shrinking
  'Blink King', // Blinking
  'Lure King', // Lurking
  'Fay King', // Faking
  'Jack King', // Jacking
  'Wok King', // Woking
  'Soak King', // Soaking
  'Cloak King', // Cloaking
  'Prank King', // Pranking

  'Miss Fortune', // Misfortune
  'Miss Understanding', // Misunderstanding
  'Miss Terious', // Mysterious
  'Miss Tanbul', // Instanbul
  'Miss Sing', // Missing
  'Miss Erable', // Miserable
  'Miss Taken', // Mistaken
  'Miss Take', // Mistake
  'Miss Behave', // Misbehave
  'Miss Chief', // Mischief
  'Miss Ion', // Mission
  'Miss Scary', // Miscarry
  'Miss Aligned', // Misaligned
  'Miss Conduct', // Misconduct
  'Miss Leading', // Misleading
  'Miss Informed', // Misinformed
  'Miss Tic', // Mystic
  'Miss Fit', // Misfit
  'Miss O\' Gin', // Mysogin
  'Miss Reading', // misreading
  'Miss Antrop', // mysanthrope
  'Miss Advenure', // misadventure
  'Miss Direction', // misdirection
  'Miss Calculated', // miscalculated
  'Miss Match', // mismatch
  'Miss Judgement', // misjudgement
  'Miss Placed', // misplaced
  'Miss Guided', // misguided
  'Miss Conception', // misconception
  'Miss Handled', // mishandled
  'Miss Represents', // misrepresent
  'Miss Pronounced', // mispronounced
  'Miss Demeanor', // misdemeanor
  'Miss Diagnosed', // misdiagnosed
  'Miss Fire', // misfire
  'Miss Hap', // mishap

  'Lady Stopia', // Dystopia
  'Iona Karr', // I own a car
  'Cliff Hunger', // Cliffhanger
  'Ella Vader', // Elevator
  'Earl E. Riser', // Early riser
  'Robyn Banks', // Robbing banks
  'Justin Thyme', // Just in time
  'Luke Warm', // Lukewarm
  'Stan Still', // Stand still
  'Carry Shik-Anh', // Curry chicken
  'Jenny Talia', // Genitalia
  'Iona Shop', // I own a shop
  'Anita Bath', // I need a bath
  'Anita Lawyer', // I need a lawyer
  'Anita Break', // I need a break
  'Anita Bier', // I need a beer
  'Will Power', // Willpower
  'Lou Pole', // Loophole
  'Dee Light', // Delight
  'Anita Mann', // I need a man
  'Sue Perb', // Superb
  'Neil Down', // Knheel down
  'Dan Druff', // Dandruff
  'Gabe Barr', // Gay bar
  'Carrie Oki', // Karaoke
  'Anna Bollick', // Anabolic
  'Barry Kade', // Barricade
  'Chris P. Bakon', // Crispy Bacon
  'Sue Vaneer', // Souvenir
  'Pete Zaria', // Pizzeria
  'Pete Za', // Pizza
  'Faye Slift', // Face lift
  'Don Keigh', // Donkey
  'Paige Turner', // Page turner
  'Mari Nara', // Marinara
  'Wayne Dear', // Reindeer
  'Penny Tration', // Penetration
  'Hugh Ass', // Huge ass
  'Barb Wire', // Barb Wire
  'Al Beeno', // Albino
  'Rocky Road', // Rocky road
  'Max Hemum', // Maximum
  'Dee Fender', // Defender
  'Ray Diation', // Radiation
  'Terri Blystressed', // Terribly stressed
  'Walter Melon', // Watermelon
  'Otto Mation', // Automation
  'Frank Einstein', // Frankeinstein
  'Anne Chovie', // Anchovy
  'Ella Mantary', // Elemantary
  'Andy Gravity', // Anti-gravity
  'Carmen Geddit', // Come and get it
  'Marsha Mellow', // Marshmallow
  'Moe Shawn', // Motion
  'Bill Ding', // Building
  'Lee Key', // Leaky
  'Perry Dox', // Paradox
  'Paul E. Styrene', // Polystyrene
  'Anna Sasin', // An assassin
  'Avery Day', // Every Day
  'Dinah Soares', // Dinosaur
  'Marge Inn', // Margin
  'Pepe Ronee', // Peperony
];

new Vue({
  router,
  render: h => h(App),
  i18n,
}).$mount('#app')
