<template>
  <div style="position: relative;">
    <div
      class="top-container"
      :class="{
        'mode-accueil': $route.name === 'Accueil',
        'mode-salon': $route.name === 'Salon',
      }"
    >
      <div class="topbar-frosted-backgound"></div>
      <div class="topbar-repeat-background" :class="{'fond-1': fond === 0, 'fond-2': fond === 1}"></div>

      <div class="sketchy-title">
        <span v-if="logo === 0" class="logo-texte-1">Sketchy</span>
        <span v-else-if="logo === 1" class="logo-texte-2">Sketchy</span>
        <LogoSketchy v-else-if="logo === 2" class="logo-svg"/>
      </div>
      <router-link class="zone-redirect" :to="{name: 'Accueil'}"/>

      <BoutonMute
        class="btn-mute"
        @click="toggleBoutonMute"
        :disabled="$session._sounds !== true"
      />
      <BoutonDiscord
        class="discord-button"
      />
      <BoutonInterrogation
        @click="openTuto"
        class="tuto"
      />
      <BoutonTwitter
        v-if="1 === 2"
        class="twitter-btn"
      />

      <div class="drapeaux">
        <DrapeauFr :class="{'is-active': $locale === 'fr'}" @click="$locale='fr'" class="drapeau" btn/>
        <DrapeauUsaUk :class="{'is-active': $locale === 'en'}" @click="$locale='en'" class="drapeau" btn/>
      </div>

      <TraitSeparation class="separator"/>

      <ModalTuto v-if="show_tuto" @close="closeTuto"/>
    </div>

    <div style="position: absolute;top: 0; left: 0;width: 100%; height: 100%;pointer-events: none;">
    </div>

  </div>

</template>

<script>
export default {
  name: 'Top',

  data() {
    return {
      show_tuto: false,
      fond: this.randBetween(0, 1),
      logo: this.randBetween(0, 2),
    };
  },

  created() {
    this.$bus.$on('open_tuto', this.openTuto);
  },

  beforeDestroy() {
    this.$bus.$off('open_tuto', this.openTuto);
  },

  methods: {
    openTuto() {
      this.show_tuto = true;

      this._matomo_trackGoal(this.MTM_GOAL_TUTO_OPEN);
    },

    closeTuto() {
      this.show_tuto = false;
      this.$bus.$emit('tuto_closed');
    },

    toggleBoutonMute() {
      this.$session._sounds = !this.$session._sounds;

      if (this.$session._sounds) {
        this._matomo_trackGoal(this.MTM_GOAL_SOUND_UNMUTE);
      } else {
        this._matomo_trackGoal(this.MTM_GOAL_SOUND_MUTE);
      }
    },
  },

  components: {
    DrapeauFr: () => import('@/resources/images/Icone_pays_fr'),
    DrapeauUsaUk: () => import('@/resources/images/Icone_pays_usa_uk'),
    DrapeauUsa: () => import('@/resources/images/Icone_pays_usa'),
    LogoSketchy: () => import('@/resources/images/LogoSketchy'),
    TraitSeparation: () => import('@/resources/images/TraitSeparation'),
    ModalTuto: () => import('@/views/components/accueil/ModalTuto'),
    BoutonInterrogation: () => import('@/views/components/global/boutons/BoutonInterrogation'),
    BoutonTwitter: () => import('@/views/components/global/boutons/BoutonTwitter'),
    BoutonDiscord: () => import('@/views/components/global/boutons/BoutonDiscord'),
    BoutonMute: () => import('@/views/components/global/boutons/BoutonMute'),
  },

}
</script>

<style lang="scss" scoped>
@import '~@/resources/libraries/bulma-0.9.0/sass/utilities/_all.sass';

.top-container {
  position: relative;

  width: 100%;
  height: 150px;

  overflow: hidden;

  @include mobile {
    height: 75px;

    //background-color: red;
    .sketchy-title {
      width: 100px;
    }
  }

  &:before {

  }

  .topbar-frosted-backgound {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-image: url('~@/resources/images/notebook_background_frosted.png');
    background-repeat: repeat;
    -webkit-mask-image: -webkit-gradient(
        linear,
        left 15%,
        left bottom,
        from(rgba(0, 0, 0, 0.75)),
        to(rgba(0, 0, 0, 0))
    );
  }

  .topbar-repeat-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background: linear-gradient(179deg, rgba(0, 0, 0, 0.26) 75%, rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: -webkit-gradient(
        linear,
        left 15%,
        left bottom,
        from(rgba(0, 0, 0, 0.75)),
        to(rgba(0, 0, 0, 0))
    );
    background-repeat: repeat;
  }

  .topbar-repeat-background.fond-1 {
    background: linear-gradient(179deg, rgba(0, 0, 0, 0.26) 75%, rgba(0, 0, 0, 0) 100%), url('~@/resources/images/fond_doodle.webp');
    background-size: 22%;
  }

  .topbar-repeat-background.fond-2 {
    background: linear-gradient(179deg, rgba(0, 0, 0, 0.26) 75%, rgba(0, 0, 0, 0) 100%), url('~@/resources/images/fond.jpg');
    background-size: 18%;
  }

  .logo-texte-1 {
    pointer-events: none;

    display: block;
    position: absolute;

    font-family: cabin_sketch_bold;
    font-size: 100px;
    color: #000;

    left: 11%;
    bottom: -23px;

    user-select: none;

    @include mobile {
      width: 100%;
      left: 0;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      top: 0;
      font-size: 60px;
    }
  }

  .logo-texte-2 {
    pointer-events: none;

    display: block;
    position: absolute;

    font-family: cabin_sketch_regular;
    font-size: 100px;
    color: #000;

    left: 11%;
    bottom: -23px;

    user-select: none;

    @include mobile {
      width: 100%;
      left: 0;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      top: 0;
      font-size: 60px;
    }
  }

  .zone-redirect {
    display: block;
    position: absolute;

    left: 11%;
    bottom: 12px;
    width: 350px;
    height: 80px;

    user-select: none;
  }

  .logo-svg {
    pointer-events: none;

    display: block;
    position: absolute;

    left: 11.5%;
    bottom: -5px;
    width: 320px;

    @include mobile {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 5px;
      width: 160px;
    }
  }

  .drapeaux {
    display: block;
    position: absolute;

    top: 10px;
    right: 100px;

    @include mobile {
      right: 0px;
      top: 5px;
    }

    .drapeau {
      width: 50px;
      height: auto;
      margin-right: 8px;

      opacity: 0.4;

      &.is-active {
        opacity: 1;
      }

      @include mobile {
        display: block;
        width: 39px;
      }

      &:not(:first-of-type) {
        margin-top: 4px;
      }
    }
  }

  .tuto {
    display: block;
    position: absolute;
    height: 40px;
    width: auto;
    top: 10px;
    left: 100px;
    @include mobile {
      left: 6px;
      top: 37px;
      height: 27px !important;
      width: 27px !important;
    }
  }

  .twitter-btn {
    display: block;
    position: absolute;
    height: 40px;
    width: auto;
    top: 10px;
    left: 145px;

    @include mobile {
      left: 6px;
      top: 6px;
      height: 27px !important;
      width: 27px !important;
    }
  }

  .discord-button {
    display: block;
    position: absolute;
    height: 40px;
    width: auto;
    top: 10px;
    //left: 190px;
    left: 145px;

    @include mobile {
      left: 37px;
      top: 6px;
      height: 27px !important;
      width: 27px !important;
    }
  }

  &.mode-salon {
    @include mobile {
      .twitter-btn, .tuto, .discord-button {
        display: none !important;
      }
    }
  }

  &.mode-accueil {
    @include mobile {
      .btn-mute {
        display: none !important;
      }
    }
  }

  .btn-mute {
    display: block;
    position: absolute;
    height: 60px;
    width: auto;
    top: 0;
    left: 10px;

    @include mobile {
      top: 5px;
      left: 6px;
    }
  }

  .separator {
    display: block;
    position: absolute;

    bottom: 4px;

    $margin: 180px;
    width: calc(100% - #{$margin});
    left: $margin / 2;

    @include mobile {
      $margin: 70px;
      width: calc(100% - #{$margin});
      left: $margin / 2;
    }
  }
}
</style>
