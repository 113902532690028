<template>
  <div id="app" class="sketchy-app">
    <!-- "Header" de la page (en gros, la navbar) -->
    <div id="app-header">
      <router-view name="navbar"/>
    </div>

    <!-- Contenu de la page, avec la sidebar et le "vrai" contenu -->
    <div id="app-content">
      <router-view name="sidebar" id="sidebar"/>

      <div id="app-container" ref="appContainer">
        <NotificationTuto/>

        <router-view id="app-view"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',

  created() {
    this.$bus.$on('scrollTop', this.scrollTop);
  },

  beforeDestroy() {
    this.$bus.$off('scrollTop', this.scrollTop);
  },

  methods: {
    scrollTop() {
      if ('appContainer' in this.$refs && this.$refs.appContainer !== null)
        this.$refs.appContainer.scrollTop = 0;
    }
  },

  components: {
    NotificationTuto: () => import('@/views/components/global/nav/NotificationTuto'),
  },
}
</script>

<style lang="scss">
  @import 'resources/libraries/bulma-0.9.0/bulma.sass';
  @import 'resources/libraries/bulma-tooltip-1.2/src/sass/index';
  @import 'resources/style/helpers/_all';

  @import 'resources/fonts/roboto/_roboto';
  @import 'resources/fonts/roboto/_roboto-mono';
  @import 'resources/fonts/cabin_sketch/stylesheet.css';

  html, body {
    width: 100%;
    height: 100%;

    overflow-y: auto;
  }

  #app {
    font-family: 'Roboto', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    color: #2c3e50;

    position: absolute;
    height: 100%;
    width: 100%;

    display: table;

    background-image: url('~@/resources/images/notebook_background.png');
    background-repeat: repeat;
  }

  // Disposition flex
  // https://weblog.west-wind.com/posts/2017/nov/11/flexing-your-html-layout-muscles-with-flexbox
  #app {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    height: 100%;

    overflow-x: auto;
    overflow-y: visible;
    @include nice-scrollbar;

    #app-header {
      flex: none;

      color: white;
    }

    #app-content {
      // resize in container
      flex: 1;

      overflow-x: hidden;

      // new flex container for content
      display: flex;
      flex-direction: row;

      #app-sidebar {
        flex: none;

        overflow: auto;
        overflow-scrolling: touch;
        -webkit-overflow-scrolling: touch;
        @include nice-scrollbar;

        white-space: nowrap;
      }

      #app-container {
        flex: 1 1 auto;

        //padding: 18px 20px 0;
        width: 100%;

        overflow-y: auto;
        overflow-scrolling: touch;
        -webkit-overflow-scrolling: touch;
        @include nice-scrollbar;

        @include mobile {
          overflow-y: visible;
        }

        display: flex; // new flex for content
        flex-direction: column;

        #app-view {
          flex-grow: 1;
        }
      }

      #app-footer {
        flex-shrink: 0;
      }
    }
  }

</style>
