import Vue from 'vue'
import VueRouter from 'vue-router'

import Top from '@/views/components/global/nav/Top';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Accueil',
        components: {
            default: () => import('@/views/Accueil'),
            navbar: Top,
        },
    }, {
        path: '/tuto',
        name: 'TutoRedir',
        components: {
            default: () => import('@/views/TutoRedir'),
        },
    }, {
        path: '/salon/:salon',
        name: 'Salon',
        components: {
            default: () => import('@/views/Salon'),
            navbar: Top,
        },
    }, {
        path: '/admin/recherche_dessins',
        name: 'AdminRechercheDessins',
        components: {
            default: () => import('@/views/admin/RechercheDessins'),
            navbar: Top,
        },
    }, {
        path: '/admin/recherche_mots',
        name: 'AdminRechercheMots',
        components: {
            default: () => import('@/views/admin/RechercheMots'),
            navbar: Top,
        },
    }, {
        path: '/admin/parties',
        name: 'AdminListeDates',
        components: {
            default: () => import('@/views/admin/ListePartiesDates'),
            navbar: Top,
        },
    }, {
        path: '/admin/parties/:dir',
        name: 'AdminListeParties',
        components: {
            default: () => import('@/views/admin/ListePartiesDessins'),
            navbar: Top,
        },
    },
]

const router = new VueRouter({
    routes
})

export default router
