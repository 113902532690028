<template>
  <span style="white-space: nowrap;">
    <Nombre
        v-for="(part, i) in parts"
        style="width: inherit;height: inherit;display: inline-block;"
        :n="part"
        :key="i"
        :troisChiffres="troisChiffres"
    />
  </span>
</template>

<script>
export default {
  name: 'Nombres',

  computed: {
    parts() {
      let parts = this.n.toString().split('');
      parts.map(n => +n);
      return parts;
    },

    troisChiffres() {
      return this.n >= 100;
    },
  },

  props: ['n'],
}
</script>
