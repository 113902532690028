const logChannel = '[PLUGIN SESSION]'

export default {
    install(Vue, options) {
        const cookie_name = options.cookie_name;

        let onSet = [];

        // Création d'une sous-instance vue pour stocker/gérer les droits de manière réactive
        let vm = new Vue({
            data() {
                return {
                    data: {
                        _last_salon: undefined,
                        _last_pseudo: undefined,
                        _last_password: undefined,
                        _last_version: undefined,
                        _sounds: undefined,

                        _admin_liste_mdp: undefined,

                        _tuto_first_time_visit: undefined,
                        _notif_discord_seen: undefined,
                    },
                };
            },

            methods: {
                _persist() {
                    options.VueCookies.set(
                        cookie_name,
                        this.data,
                        null,
                        null,
                        null,
                        location.protocol === 'https:',
                        location.protocol === 'https:' ? 'None' : 'Lax',
                    );
                    console.info(logChannel, 'Session has been persisted =>', this.data);
                },

                _load() {
                    if (options.VueCookies.isKey(cookie_name)) {
                        this.set(options.VueCookies.get(cookie_name));
                        console.info(logChannel, 'Session correctement chargée =>', this.data);
                    } else {
                        console.info(logChannel, 'Session inexistante, skip du chargement.');
                        this.reset();
                        this._tuto_first_time_visit = true;
                    }
                },

                set(obj) {
                    console.log(logChannel, 'Session is being set:');
                    for (let k in obj) {
                        console.log(logChannel, '  - this.data.' + k + ' =', obj[k]);
                        this.$set(this.data, k, obj[k]);
                    }

                    this._persist();

                    for (let k in onSet) {
                        onSet[k](this.data);
                    }
                },

                reset() {
                    console.info(logChannel, 'Reset de la session');

                    this.$set(this, 'data', {});

                    for (let k in onSet) {
                        onSet[k]({});
                    }
                },

                clear() {
                    this.reset();
                    options.VueCookies.remove(cookie_name);
                    console.info(logChannel, 'Session has been cleared');
                },

                onSet(callback) {
                    onSet.push(callback);
                    callback(this.data);
                },

                seenWordsBroken() {
                    for (let i in this.data._seen_words) {
                        if (typeof this.data._seen_words[i] !== 'string') {
                            return true;
                        }
                    }
                    return false;
                },

                repairSeenWords() {
                    let newSeenWords = [];

                    for (let i in this.data._seen_words) {
                        if (typeof this.data._seen_words[i] === 'string') {
                            newSeenWords.push(this.data._seen_words[i]);
                        }
                    }

                    this.data._seen_words = newSeenWords;
                },

                pushSeenWord(word) {
                    if (typeof word !== 'string') return;

                    if ('_seen_words' in this.data && Array.isArray(this.data._seen_words)) {
                        this.data._seen_words.push(word);

                        if (this.seenWordsBroken()) {
                            console.warn('SEEN WORDS IS BROKEN');
                            this.repairSeenWords();
                            console.info('repair =>', this.data._seen_words);
                        } else {
                            console.info('seen words is not broken');
                        }

                        while (this.data._seen_words.length > 40)
                            this.data._seen_words.shift();
                    } else {
                        this.data._seen_words = [word];
                    }
                    console.info(logChannel, 'Stockage du mot', word, 'dans la liste seen_words locale');
                    console.info(logChannel, '=> ', this.data._seen_words);

                    this._persist();
                }
            },

            computed: {
                _language: {
                    get() {
                        if ('_language' in this.data) return this.data._language;
                        return undefined;
                    },
                    set(v) {
                        this.data._language = v;
                        this._persist();
                    },
                },

                _seen_words: {
                    get() {
                        if ('_seen_words' in this.data && Array.isArray(this.data._seen_words))
                            return this.data._seen_words;

                        this.data._seen_words = [];
                        return this.data._seen_words;
                    },
                    set(v) {
                        this.data._seen_words = v;
                        this._persist();
                    },
                },

                _last_salon: {
                    get() {
                        if ('_last_salon' in this.data) return this.data._last_salon;
                        return undefined;
                    },
                    set(v) {
                        this.$set(this.data, '_last_salon', v);
                        this._persist();
                    },
                },

                _last_pseudo: {
                    get() {
                        if ('_last_pseudo' in this.data) return this.data._last_pseudo;
                        return undefined;
                    },
                    set(v) {
                        this.$set(this.data, '_last_pseudo', v);
                        this._persist();
                    },
                },

                _last_password: {
                    get() {
                        if ('_last_password' in this.data) return this.data._last_password;
                        return undefined;
                    },
                    set(v) {
                        this.$set(this.data, '_last_password', v);
                        this._persist();
                    },
                },

                _last_version: {
                    get() {
                        if ('_last_version' in this.data) return this.data._last_version;
                        return undefined;
                    },
                    set(v) {
                        this.$set(this.data, '_last_version', v);
                        this._persist();
                    },
                },

                _admin_liste_mdp: {
                    get() {
                        if ('_admin_liste_mdp' in this.data) return this.data._admin_liste_mdp;
                        return undefined;
                    },
                    set(v) {
                        this.$set(this.data, '_admin_liste_mdp', v);
                        this._persist();
                    },
                },

                _sounds: {
                    get() {
                        if ('_sounds' in this.data && this.data._sounds !== undefined) return this.data._sounds;
                        return true;
                    },
                    set(v) {
                        console.log('set sounds =', v);
                        this.$set(this.data, '_sounds', v);
                        this._persist();
                    },
                },

                _notif_discord_seen: {
                    get() {
                        if ('_notif_discord_seen' in this.data && this.data._notif_discord_seen !== undefined) return this.data._notif_discord_seen;
                        return false;
                    },
                    set(v) {
                        this.$set(this.data, '_notif_discord_seen', v);
                        this._persist();
                    },
                },

                _tuto_first_time_visit: {
                    get() {
                        if ('_tuto_first_time_visit' in this.data)
                            if (this.data._tuto_first_time_visit === false || this.data._tuto_first_time_visit === true)
                                return this.data._tuto_first_time_visit;

                        return false;
                    },
                    set(v) {
                        this.$set(this.data, '_tuto_first_time_visit', v);
                        this._persist();
                    },
                },
            },
        });

        vm._load();

        Vue.prototype.$session = vm;
        Vue.session = vm;
    },
};
