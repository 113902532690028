<template>
  <component
    :is="$sketchy_nombres[subset][n][style]"
    class="baseNombre"
    :class="{'troisChiffres': troisChiffres}"
  />
</template>

<style lang="scss" scoped>
.baseNombre {
  &.troisChiffres {
    height: 43px !important;
  }
}
</style>

<script>
import NA0 from '@/views/components/global/nombre/nombres_nico/inline.nico_A0.svg';
import NA1 from '@/views/components/global/nombre/nombres_nico/inline.nico_A1.svg';
import NA2 from '@/views/components/global/nombre/nombres_nico/inline.nico_A2.svg';
import NA3 from '@/views/components/global/nombre/nombres_nico/inline.nico_A3.svg';
import NA4 from '@/views/components/global/nombre/nombres_nico/inline.nico_A4.svg';
import NA5 from '@/views/components/global/nombre/nombres_nico/inline.nico_A5.svg';
import NA6 from '@/views/components/global/nombre/nombres_nico/inline.nico_A6.svg';
import NA7 from '@/views/components/global/nombre/nombres_nico/inline.nico_A7.svg';
import NA8 from '@/views/components/global/nombre/nombres_nico/inline.nico_A8.svg';
import NA9 from '@/views/components/global/nombre/nombres_nico/inline.nico_A9.svg';

import NB0 from '@/views/components/global/nombre/nombres_nico/inline.nico_B0.svg';
import NB1 from '@/views/components/global/nombre/nombres_nico/inline.nico_B1.svg';
import NB2 from '@/views/components/global/nombre/nombres_nico/inline.nico_B2.svg';
import NB3 from '@/views/components/global/nombre/nombres_nico/inline.nico_B3.svg';
import NB4 from '@/views/components/global/nombre/nombres_nico/inline.nico_B4.svg';
import NB5 from '@/views/components/global/nombre/nombres_nico/inline.nico_B5.svg';
import NB6 from '@/views/components/global/nombre/nombres_nico/inline.nico_B6.svg';
import NB7 from '@/views/components/global/nombre/nombres_nico/inline.nico_B7.svg';
import NB8 from '@/views/components/global/nombre/nombres_nico/inline.nico_B8.svg';
import NB9 from '@/views/components/global/nombre/nombres_nico/inline.nico_B9.svg';

import NC0 from '@/views/components/global/nombre/nombres_nico/inline.nico_C0.svg';
import NC1 from '@/views/components/global/nombre/nombres_nico/inline.nico_C1.svg';
import NC2 from '@/views/components/global/nombre/nombres_nico/inline.nico_C2.svg';
import NC3 from '@/views/components/global/nombre/nombres_nico/inline.nico_C3.svg';
import NC4 from '@/views/components/global/nombre/nombres_nico/inline.nico_C4.svg';
import NC5 from '@/views/components/global/nombre/nombres_nico/inline.nico_C5.svg';
import NC6 from '@/views/components/global/nombre/nombres_nico/inline.nico_C6.svg';
import NC7 from '@/views/components/global/nombre/nombres_nico/inline.nico_C7.svg';
import NC8 from '@/views/components/global/nombre/nombres_nico/inline.nico_C8.svg';
import NC9 from '@/views/components/global/nombre/nombres_nico/inline.nico_C9.svg';

export default {
  name: 'Nombre',

  data() {
    return {
      subset: 0,
      style: this.randBetween(0, 2),
    };
  },

  watch: {
    n: {
      immediate: true,
      handler: function() {
        this.style = this.randBetween(0, 2);
      },
    },
  },

  computed: {
    $sketchy_nombres() {
      return {
        0: [
          [NA0, NB0, NC0],
          [NA1, NB1, NC1],
          [NA2, NB2, NC2],
          [NA3, NB3, NC3],
          [NA4, NB4, NC4],
          [NA5, NB5, NC5],
          [NA6, NB6, NC6],
          [NA7, NB7, NC7],
          [NA8, NB8, NC8],
          [NA9, NB9, NC9],
        ],
      };
    },
  },

  props: ['n', 'troisChiffres'],
}
</script>